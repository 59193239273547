/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import * as React from "react";
import { Course, HTMLContent } from "@sparkademy/app-common/models/course";
import { HTMLContentWrapper } from "./HTMLContentWrapper";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { User } from "@sparkademy/app-common/models/user";
import { useAtom } from "jotai";
import { courseAtom } from "../../stores/course";
import { CDN_ASSETS_URL } from "../../data/directus";

export const BlockUnitHTMLContent: React.FC<{
  item: HTMLContent;
}> = ({ item }) => {
  const { currentUser } = useSessionContext();
  const [course] = useAtom(courseAtom);

  let processedHtml = replaceImageSource(item.content);
  processedHtml = replaceEmbeddedUserVars(processedHtml, currentUser, course);

  return (
    <Box>
      <HTMLContentWrapper html={processedHtml} />
    </Box>
  );
};

function replaceEmbeddedUserVars(html: string, user: User | null, course: Course | null) {
  if (!user || !html) return html;

  return html
    .replace(/\$\{user_email\}/g, user.data.email)
    .replace(/\$\{user_cohort\}/g, user.data.cohort_id)
    .replace(/\$\{course_title\}/g, course?.title || "[BLANK]");
}

function replaceImageSource(html: string) {
  // replace the image source with the CDN URL so we cache CMS assets
  return html.replace(
    /src=\"https\:\/\/sparkademy.directus.app\/assets/g,
    `src="${CDN_ASSETS_URL}`
  );
}
