import * as React from "react";
import { Box, Heading, Text, Textarea, Select } from "theme-ui";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Button } from "@sparkademy/app-common/elements/Button";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { UserExtraInfo } from "@sparkademy/app-common/models/user";
import { FourSquares } from "../../models/four-squares";
import { addUserExtraInfo } from "../../services/http-api-service";
import { Logger } from "@sparkademy/app-common/services/logger-service";
import { TrackingService } from "../../services/tracking-service";
import {
  pronounOpts,
  roleOpts,
  responsibilityOpts,
  countryOpts,
  referralSourceOpts,
  motivationOpts,
  FormSelectField,
  FormTextField,
  validateFields,
} from "@sparkademy/app-common/components/FormFields";

type UserExtraInfoState = UserExtraInfo & { errors: { [field: string]: string } };

export const CollectExtraUserInfo: React.FC<{
  userId: string;
  cohortId: string;
  gameState: FourSquares;
  updateGame: (choice: number | null) => void;
}> = ({ userId, cohortId, gameState, updateGame }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [userAttrs, setUserAttrs] = React.useState<UserExtraInfoState>({
    pronoun: "",
    jobRole: "",
    responsibility: "",
    country: "",
    referralSource: "",
    educationalBackground: "",
    professionalBackground: "",
    programExpectation: "",
    programMotivation: "",
    errors: {},
  });

  const viewStartTimeRef = React.useRef<Date>(new Date());
  React.useEffect(() => {
    // run this once on mount
    viewStartTimeRef.current = new Date();
    return () => {
      // run this on unmount
      TrackingService.timeSpentOnMotivationCheck(cohortId, viewStartTimeRef.current, new Date());
    };
  }, []);

  const attributeToLabelMap = {
    pronoun: "Pronoun",
    jobRole: "Job Role",
    responsibility: "Responsibility",
    country: "Country",
    referralSource: "Referral Source",
    educationalBackground: "Educational Background",
    professionalBackground: "Professional Background",
    programExpectation: "Program Expectation",
    programMotivation: "Program Motivation",
  };

  if (loading) {
    return <Loader />;
  }

  const onChangeHandler = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
    setUserAttrs({
      ...userAttrs,
      [field]: event.currentTarget.value,
      errors: { ...userAttrs.errors, [field]: "" },
    });

  return (
    <Container
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: "-50px",
      }}
    >
      <form
        onSubmit={event => {
          event.preventDefault();
          const errors = validateFields(userAttrs, attributeToLabelMap);

          if (Object.keys(errors).length === 0) {
            setLoading(true);
            addUserExtraInfo(userId, userAttrs)
              .then(() => updateGame(null))
              .catch(ex => {
                Logger.error(ex);
                setLoading(false);
              });
          } else {
            setUserAttrs({ ...userAttrs, errors });
          }
        }}
      >
        <Box
          sx={{
            mt: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ mb: 8 }}>
            <Heading as="h1" sx={{}}>
              Candidate Information Survey
            </Heading>
            <Text sx={{ mt: 2 }}>Please answer all of the questions below.</Text>
          </Box>

          <FormSelectField
            id="pronoun"
            text="Preferred Pronoun"
            options={pronounOpts}
            selected={userAttrs.pronoun}
            hasError={!!userAttrs.errors["pronoun"]}
            onChange={onChangeHandler("pronoun")}
          />

          <FormSelectField
            id="jobRole"
            text="Role"
            options={roleOpts}
            selected={userAttrs.jobRole || ""}
            hasError={!!userAttrs.errors["jobRole"]}
            onChange={onChangeHandler("jobRole")}
            note="(If more than one apply, please select the most relevant one for you)"
          />

          <FormSelectField
            id="responsibility"
            text="Responsibilities"
            options={responsibilityOpts}
            selected={userAttrs.responsibility || ""}
            hasError={!!userAttrs.errors["responsibility"]}
            onChange={onChangeHandler("responsibility")}
            note="(If more than one apply, please select the most relevant one for you)"
          />

          <FormSelectField
            id="country"
            text="Country"
            options={countryOpts}
            selected={userAttrs.country}
            hasError={!!userAttrs.errors["country"]}
            onChange={onChangeHandler("country")}
          />

          <FormSelectField
            id="referralSource"
            text="How did you hear about this program?"
            options={referralSourceOpts}
            selected={userAttrs.referralSource}
            hasError={!!userAttrs.errors["referralSource"]}
            onChange={onChangeHandler("referralSource")}
            note="(If more than one apply, please select the most relevant one for you)"
          />

          <FormTextField
            id="educationalBackground"
            text="What is your educational background?"
            value={userAttrs.educationalBackground || ""}
            hasError={!!userAttrs.errors["educationalBackground"]}
            onChange={onChangeHandler("educationalBackground")}
          />

          <FormTextField
            id="professionalBackground"
            text="What is your professional background?"
            value={userAttrs.professionalBackground || ""}
            hasError={!!userAttrs.errors["professionalBackground"]}
            onChange={onChangeHandler("professionalBackground")}
          />

          <FormTextField
            id="programExpectation"
            text="What impact do you expect this program to have? (Professional and Personal)"
            value={userAttrs.programExpectation || ""}
            hasError={!!userAttrs.errors["programExpectation"]}
            onChange={onChangeHandler("programExpectation")}
          />

          <FormSelectField
            id="programMotivation"
            text="Which of these statements best explains your motivation to take part in this program?"
            options={motivationOpts}
            selected={userAttrs.programMotivation || ""}
            hasError={!!userAttrs.errors["programMotivation"]}
            onChange={onChangeHandler("programMotivation")}
            note="(If more than one apply, please select the most relevant one for you)"
          />
        </Box>
        <Box
          sx={{
            my: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {new Array(4).fill(0).map((e, i) => (
            <Box
              key={`box-${i}`}
              sx={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                bg: i === 0 ? "new.primary.purple" : "new.secondary.grey",
                mx: 2,
              }}
            />
          ))}
        </Box>

        <Button
          type="submit"
          disabled={Object.values(userAttrs.errors).filter(e => !!e).length > 0}
        >
          Next
        </Button>
      </form>
    </Container>
  );
};
