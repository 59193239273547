/** @jsx jsx */
import { jsx, Box, Text, Heading, Label } from "theme-ui";
import * as React from "react";
import {
  QuizContent,
  QuizQuestion,
  QuizQuestionOption,
} from "@sparkademy/app-common/models/course";
import { Radio } from "@sparkademy/app-common/elements/Radio";
import { useParams } from "react-router-dom";
import { fetchQuizAnswers, saveQuizAnswer } from "../../services/http-api-service";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { TrackingService } from "../../services/tracking-service";

export const BlockUnitQuizContent: React.FC<{
  quiz: QuizContent;
}> = ({ quiz }) => {
  const { currentUser } = useSessionContext();

  React.useEffect(() => {
    fetchQuizAnswers(quiz.id, currentUser!).then(answers => {
      const mappedAnswers = answers.reduce((acc, a) => {
        acc[a.questionId] = a.index;
        return acc;
      }, {} as CheckedOptionsMap);

      // no need to update state if there are no previously saved answers
      if (Object.keys(mappedAnswers).length > 0) {
        setCheckedOptionsMap(mappedAnswers);
      }
    });
  }, [currentUser, quiz.id]);

  const initialState = quiz.questions.reduce((acc, question) => {
    acc[question.id] = -1;
    return acc;
  }, {} as CheckedOptionsMap);

  const { courseId, lessonId, blockId, unitId } = useParams<{
    courseId: string;
    lessonId: string;
    blockId: string;
    unitId: string;
  }>();

  const [checkedOptionsMap, setCheckedOptionsMap] = React.useState<CheckedOptionsMap>(initialState);
  const onChange = (questionId: string, index: number) => {
    setCheckedOptionsMap({
      ...checkedOptionsMap,
      [questionId]: index,
    });

    // saves the answer on our database
    const questionIndex = quiz.questions.findIndex(q => q.id === questionId);
    const answer = quiz.questions[questionIndex].options[index];
    saveQuizAnswer(
      currentUser?.data.cohort_id!,
      courseId,
      lessonId,
      blockId,
      unitId,
      quiz.id,
      questionId,
      questionIndex,
      index,
      answer.weight,
      currentUser!
    );

    // additionally send the event to Segment
    TrackingService.QuizAnswerSubmitted(
      courseId,
      lessonId,
      blockId,
      unitId,
      quiz.id,
      questionId,
      index,
      answer.weight
    );
  };

  if (quiz.questions.length === 0) {
    return null;
  }

  return (
    <Box sx={{ my: "48px" }} className="quiz-container">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Heading as="h3" sx={{ fontWeight: 700, fontSize: 2 }}>
          Quiz
        </Heading>
        <Text
          sx={{
            color: "new.secondary.violet",
            border: "1px solid",
            borderColor: "new.secondary.violet",
            borderRadius: "3px",
            marginLeft: "16px",
            padding: "4px 16px",
            fontSize: "12px",
          }}
        >
          Ungraded
        </Text>
      </Box>

      <Box sx={{ mt: ["16px", "32px"] }}>
        <div dangerouslySetInnerHTML={{ __html: quiz.content }} />
      </Box>

      {quiz.questions.map((question, index) => (
        <QuizContentQuestion
          key={index}
          question={question}
          number={index + 1}
          checkedIndex={checkedOptionsMap[question.id]}
          onChange={onChange}
        />
      ))}
    </Box>
  );
};

export const QuizContentQuestion: React.FC<{
  question: QuizQuestion;
  number: number;
  checkedIndex: number;
  onChange: (questionId: string, index: number) => void;
}> = ({ question, number, checkedIndex, onChange }) => {
  return (
    <Box className="quiz-question">
      <Text sx={{ my: "32px", fontSize: "16px", fontWeight: 500 }}>
        {`${number}. ${question.text}`}
      </Text>

      <Box sx={{ width: ["100%", "894px"] }}>
        {question.options.map((option, index) => (
          <QuestionOption
            questionId={question.id}
            option={option}
            key={index}
            index={index}
            checkedIndex={checkedIndex}
            onChange={onChange}
          />
        ))}
      </Box>
    </Box>
  );
};

export const QuestionOption: React.FC<{
  questionId: string;
  option: QuizQuestionOption;
  index: number;
  checkedIndex: number;
  onChange: (questionId: string, index: number) => void;
}> = ({ questionId, option, index, checkedIndex, onChange }) => {
  const isChecked = checkedIndex === index;
  const colorStyles = {
    borderColor: "new.primary.darkGrey",
    bg: "new.primary.white",
  };

  if (checkedIndex > -1) {
    if (option.weight > 0) {
      colorStyles.borderColor = "new.primary.green";
      colorStyles.bg = "rgba(23, 229, 160, 0.1)";
    } else if (isChecked && option.weight === 0) {
      colorStyles.borderColor = "new.secondary.red";
      colorStyles.bg = "rgba(255, 95, 95, 0.1);";
    }
  }

  return (
    <React.Fragment>
      <Box
        key={index}
        className="quiz-question-option"
        sx={{
          display: "flex",
          border: "1px solid",
          borderRadius: 5,
          py: "10px",
          px: "20px",
          ":not(:first-of-type)": {
            mt: "12px",
          },
          ...colorStyles,
        }}
      >
        <Label sx={{ fontSize: "16px", display: "flex", alignItems: "center" }}>
          <Box sx={{ minWidth: 25 }}>
            <Radio
              sx={{
                flex: "0 0 auto",
                bg: "transparent",
                width: 25,
                height: 25,
                m: 0,
              }}
              name={questionId}
              checked={isChecked}
              onChange={() => onChange(questionId, index)}
            />
          </Box>
          <Box sx={{ ml: 5, display: "inline" }} />
          <Box sx={{ display: "inline", cursor: "pointer", lineHeight: "25px" }}>{option.text}</Box>
        </Label>
        <Box sx={{ mt: 4 }} />
      </Box>

      {isChecked && option.explanation && (
        <Box
          className="explanation"
          sx={{
            fontSize: "16px",
            bg: "new.secondary.lightGrey",
            borderRadius: 5,
            py: "8px",
            px: "20px",
            mt: "5px",
          }}
        >
          {option.explanation}
        </Box>
      )}
    </React.Fragment>
  );
};

type CheckedOptionsMap = {
  [questionId: string]: number;
};
