import { Box, Heading } from "theme-ui";

import React from "react";
import { Layout, LayoutHeader } from "../components/Layout";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { useHistory, Redirect } from "react-router-dom";
import VisuallyHidden from "@reach/visually-hidden";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationEmail1 } from "@sparkademy/app-common/materials/illustrations/email1.svg";
import { ReactComponent as IllustrationEmail2 } from "@sparkademy/app-common/materials/illustrations/email2.svg";
import { ReactComponent as IllustrationPersonSad } from "@sparkademy/app-common/materials/illustrations/person-sad.svg";
import { Input } from "@sparkademy/app-common/elements/Input";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { useUserData } from "../hooks/useUserData";
import { uiCopy } from "../data/ui-copy";
import { authenticate, login as loginAPI } from "../services/http-api-service";
import isEmail from "@sparkademy/app-common/utils/isEmail";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";

enum STATES {
  IDLE = "IDLE",
  MAGIC_LOADING = "MAGIC_LOADING",
  MAGIC_LOADED = "MAGIC_LOAED",
  MAGIC_ERROR = "MAGIC_ERROR",

  FORM_LOADING = "FORM_LOADING",
  FORM_ERROR = "FORM_ERROR",
  FORM_SUCCESS = "FORM_SUCCESS",
}

const MAGIC_LINK_KEY = "token";

export const Login: React.FC = () => {
  const { currentUser, login, logout, redirectPathOnAuthentication } = useSessionContext();
  const { sync } = useUserData();

  const currentHistory = useHistory();
  const query = useQuery();
  const source = query.get("src") !== null ? query.get("src")! : undefined;
  const next = query.get("next") !== null ? query.get("next")! : undefined;
  const hasMagicLinkToken = query.has(MAGIC_LINK_KEY);
  const [state, setState] = React.useState<STATES>(STATES.IDLE);
  const token = query.get(MAGIC_LINK_KEY);

  // eslint-disable-next-line
  const [error, setError] = React.useState<false | string>(false);

  React.useEffect(() => {
    async function loginAsync() {
      if (currentUser) {
        return;
      }
      if (!hasMagicLinkToken) {
        return;
      }
      if (!token) {
        return;
      }
      if (
        state === STATES.MAGIC_LOADING ||
        state === STATES.MAGIC_LOADED ||
        state === STATES.MAGIC_ERROR
      ) {
        return;
      }

      setState(STATES.MAGIC_LOADING);
      try {
        const currentUser = await authenticate(token);
        if (currentUser) {
          login(currentUser);
          setState(STATES.MAGIC_LOADED);
        }
      } catch (ex) {
        console.log("ex", ex);
        logout();
        currentHistory.push("/error?reason=login");
      }
    }
    loginAsync();
  }, [currentHistory, currentUser, hasMagicLinkToken, login, logout, state, sync, token]);

  const [email, setEmail] = React.useState("");
  const [isEmailValid, setIsEmailValid] = React.useState(false);

  React.useEffect(() => {
    if (email.trim().length > 0) {
      const isValid = isEmail(email);
      setIsEmailValid(isValid);
    }
  }, [email]);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (state === STATES.FORM_LOADING) {
      return;
    }
    setState(STATES.FORM_LOADING);
    try {
      await loginAPI(email, next, source);
      setState(STATES.FORM_SUCCESS);
    } catch (ex) {
      setError(ex.message);
      setState(STATES.FORM_ERROR);
    }
  }

  if (hasMagicLinkToken && currentUser && state === STATES.MAGIC_LOADED) {
    if (next && next.indexOf("http") === 0) {
      window.location.href = next;

      return (
        <Layout sx={{ bg: "new.primary.white" }}>
          <Loader />
        </Layout>
      );
    } else {
      return <Redirect to={next || redirectPathOnAuthentication} />;
    }
  }

  if (state === STATES.MAGIC_ERROR) {
    return <Redirect to={"/"} />;
  }

  if (currentUser && !hasMagicLinkToken) {
    if (next) {
      return <Redirect to={next} />;
    }
    return <Redirect to={"/"} />;
  }

  if (state === STATES.MAGIC_LOADING || state === STATES.FORM_LOADING) {
    return (
      <Layout sx={{ bg: "new.primary.white" }}>
        <Loader />
      </Layout>
    );
  }

  return (
    <LayoutHeader sx={{ bg: "new.primary.white" }}>
      <Container
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 13,
        }}
      >
        <Box
          sx={{
            maxWidth: "90ch",
            textAlign: "center",
          }}
        >
          {state === STATES.IDLE && (
            <Container
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: 170,
                  height: 170,
                  svg: {
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <IllustrationEmail1 />
              </Box>
              <Heading
                as="h1"
                sx={{
                  fontSize: 3,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {uiCopy.LOGIN_IDLE_LEAD}
              </Heading>
            </Container>
          )}

          {state === STATES.FORM_ERROR && (
            <Container
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: 170,
                  height: 170,
                  svg: {
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <IllustrationPersonSad />
              </Box>
              <Heading
                as="h1"
                sx={{
                  fontSize: 3,
                  fontWeight: "bold",
                  textAlign: "center",
                  mb: 3,
                }}
              >
                {uiCopy.LOGIN_ERROR_TITLE}
              </Heading>
              <Narrative
                sx={{
                  mt: 4,
                  a: {
                    color: "brand.blue",
                  },
                }}
              >
                {uiCopy.LOGIN_ERROR_LEAD()}
              </Narrative>
            </Container>
          )}

          {state === STATES.FORM_SUCCESS ? (
            <Container
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: 170,
                  height: 170,
                  svg: {
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <IllustrationEmail2 />
              </Box>
              <Heading
                as="h1"
                sx={{
                  fontSize: 3,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {uiCopy.LOGIN_SUBMITTED_TITLE}
              </Heading>
              <Narrative
                sx={{
                  mt: 4,
                }}
              >
                {uiCopy.LOGIN_SUBMITTED_LEAD()}
              </Narrative>
              <Box
                sx={{
                  mt: 6,
                  p: { m: 0, height: "auto" },
                }}
              ></Box>
            </Container>
          ) : null}
        </Box>

        {(state === STATES.IDLE || state === STATES.FORM_ERROR) && (
          <Container
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <form onSubmit={onSubmit}>
              <Box
                sx={{
                  mt: 10,
                }}
              >
                <VisuallyHidden>
                  <label htmlFor="email">Email Address</label>
                </VisuallyHidden>
                <Input
                  type="email"
                  value={email}
                  onChange={event => setEmail(event.currentTarget.value)}
                  placeholder="Email Address"
                  sx={{
                    width: [330, 500],
                    ":focus": {
                      borderColor:
                        email.trim().length > 0
                          ? isEmailValid
                            ? "new.advancedGreen"
                            : "new.failingRed"
                          : undefined,
                    },
                  }}
                />
              </Box>
              <Button
                type="submit"
                sx={{
                  mt: 10,
                }}
                disabled={!isEmailValid}
              >
                {uiCopy.LOGIN_IDLE_FORM_SUBMIT_LABEL}
              </Button>
            </form>
          </Container>
        )}
      </Container>
    </LayoutHeader>
  );
};
