import * as React from "react";
import LogRocket from "logrocket";
import VisuallyHidden from "@reach/visually-hidden";
import { Layout } from "../Layout";
import { EmpathyController } from "./EmpathyController";
import { ReactComponent as IllustrationActivity } from "@sparkademy/app-common/materials/illustrations/activity.svg";
import { ReactComponent as IllustrationPerson } from "@sparkademy/app-common/materials/illustrations/person.svg";
import { ReactComponent as IllustrationSchedule } from "@sparkademy/app-common/materials/illustrations/schedule.svg";

import { Container, ContainerSmall } from "@sparkademy/app-common/elements/Container";
import { themeNew } from "@sparkademy/app-common/materials/theme";
import { ThemeProvider, Box, Heading } from "theme-ui";
import { Button, ButtonAnchor } from "@sparkademy/app-common/elements/Button";
import { uiCopy } from "../../data/ui-copy";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import {
  empathyTestStatus,
  empathyMatchingEmotionCreate,
  empathyUnderstandEmotionsCreate,
  empathyUnderstandEmotionsFinish,
  empathyMatchingEmotionFinish,
  empathyImplementEmotionsCreate,
  empathyImplementEmotionsFinish,
} from "../../services/http-api-service";

import { TrackingService } from "../../services/tracking-service";
import { Input } from "@sparkademy/app-common/elements/Input";
import useEventListener from "@use-it/event-listener";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";
import isEmail from "@sparkademy/app-common/utils/isEmail";
import { useHistory } from "react-router-dom";

enum Screens {
  Email = "Email",
  TestAlreadyTaken = "TestAlreadyTaken",
  ContinueTest = "ContinueTest",
  Explanation = "Explanation",
  EmotionMatching = "EmotionMatching",
  UnderstandingEmotions = "UnderstandingEmotions",
  ImplementEmotions = "ImplementEmotions",
  Booking = "Booking", // Some cohorts go to the booking screen instead of the end.
  End = "End",
}

export const EmpathyTest: React.FC = () => {
  const history = useHistory();

  const [isEmailValid, setIsEmailValid] = React.useState(false);

  // Get the email saved from a previous test (gambling game)
  const storedEmail = localStorage.getItem("gamblingTestEmail");
  const [email, setEmail] = React.useState(storedEmail || "");
  const [values, setValues] = React.useState<{
    firstName: string;
    lastName: string;
    email: string;
    errors: { [field: string]: string };
  }>({
    firstName: "",
    lastName: "",
    email: "",
    errors: {},
  });

  const [screen, setScreen] = React.useState<Screens>(
    email !== "" ? Screens.Explanation : Screens.Email
  );
  const [resumeTestScreen, setResumeTestScreen] = React.useState<Screens | null>(null);

  const query = useQuery();
  const cohort = query.get("cohort") || "demo_greekhorse";
  const skipTo = query.get("skipTo");
  const bookingLink =
    "https://outlook.office365.com/owa/calendar/SparkAdemyAG@sparkademy.com/bookings/s/vBb7wT-_o0yJ1po2wi-qhg2";

  const onSubmitEmail = React.useCallback(
    async (event?: React.FormEvent<HTMLFormElement>) => {
      event && event.preventDefault();

      const testStatus = await empathyTestStatus(email, cohort);

      if (testStatus["understanding_emotions"] === false) {
        setScreen(Screens.Explanation);
      } else if (testStatus["emotion_matching"] === false) {
        setResumeTestScreen(Screens.EmotionMatching);
        setScreen(Screens.ContinueTest);
      } else {
        TrackingService.attemptToRetakeSparkCheck(email, "empathy_test");
        history.push("/error?reason=spark-check-taken");
      }

      LogRocket.identify(email);
    },
    [email, history, cohort]
  );

  React.useEffect(() => {
    const isValid = isEmail(email);
    setIsEmailValid(isValid);
  }, [email]);

  React.useEffect(() => {
    if (storedEmail) {
      // Submit the email saved in localStorage by previous test
      // This email is set in the part 1 of the test (gambling game) to avoid having the user to type it twice
      onSubmitEmail();
      localStorage.removeItem("gamblingTestEmail");
    }
  }, [storedEmail, onSubmitEmail]);

  React.useEffect(() => {
    if (!skipTo) {
      return;
    }

    if (Object.values(Screens).includes(skipTo as Screens)) {
      setScreen(skipTo as Screens);
    }
  }, [skipTo]);

  useEventListener("keydown", (event: KeyboardEvent) => {
    // @ts-ignore
    if (screen === Screens.Explanation && event.keyCode === 13) {
      nextScreen();
    }
  });

  function nextScreen() {
    switch (screen) {
      case Screens.Email:
        setScreen(Screens.Explanation);
        break;
      case Screens.Explanation:
        setScreen(Screens.UnderstandingEmotions);
        break;
      case Screens.UnderstandingEmotions:
        setScreen(Screens.EmotionMatching);
        break;
      case Screens.EmotionMatching:
        if (bookingLink) {
          setScreen(Screens.Booking);
        } else {
          setScreen(Screens.End);
        }
        // setScreen(Screens.ImplementEmotions);
        break;
      case Screens.ImplementEmotions:
        bookingLink ? setScreen(Screens.Booking) : setScreen(Screens.End);
        break;
      default:
        break;
    }
  }

  return (
    <ThemeProvider theme={themeNew}>
      <Layout
        sx={{
          bg: "new.primary.white",
        }}
        logoIsClickable={false}
        hideFooter
      >
        <ContainerSmall
          sx={{
            position: "relative",
            pt: [10, 14],
            pb: 7,
            maxWidth: "66ch",
          }}
        >
          {screen === Screens.Email ? (
            <Box sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  mx: "auto",
                  width: 170,
                  height: 170,
                  svg: {
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <IllustrationPerson />
              </Box>

              <Heading
                as="h1"
                sx={{
                  fontSize: [2, 3],
                  fontWeight: "bold",
                  textAlign: "center",
                  mb: 10,
                }}
              >
                Welcome to part 2 of the Spark Check
              </Heading>
              <Narrative>
                The second part takes approximately 20 minutes to complete.
                <br />
                Please make sure you will not be interrupted for the duration of the test, that you
                are in a quiet space and that you are free from distractions (like your mobile
                phone).
              </Narrative>
              <form onSubmit={onSubmitEmail}>
                <Box
                  sx={{
                    mt: 10,
                  }}
                >
                  <VisuallyHidden>
                    <label htmlFor="email">Email Address</label>
                  </VisuallyHidden>
                  <Input
                    type="email"
                    value={email}
                    onChange={event => setEmail(event.currentTarget.value)}
                    placeholder="Email Address"
                    sx={{
                      width: [330, 500],
                      ":focus": {
                        borderColor:
                          email.trim().length > 0
                            ? isEmailValid
                              ? "new.advancedGreen"
                              : "new.failingRed"
                            : undefined,
                      },
                    }}
                  />
                </Box>
                <Box sx={{ mt: 10 }} />
                <Button type="submit" disabled={!isEmailValid}>
                  Next
                </Button>
              </form>
            </Box>
          ) : null}

          {screen === Screens.ContinueTest && (
            <Container
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: 170,
                  height: 170,
                  svg: {
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <IllustrationPerson />
              </Box>
              <Heading
                as="h1"
                sx={{
                  fontSize: [2, 3],
                  fontWeight: "bold",
                  textAlign: "center",
                  mb: 10,
                }}
              >
                Welcome Back
              </Heading>
              <Narrative>
                You exited the test early, but we caught you :) Click the button below to continue
                from where you left off.
              </Narrative>
              <Box sx={{ mt: 10 }} />
              <Button onClick={() => resumeTestScreen && setScreen(resumeTestScreen)}>
                Continue Test
              </Button>
              <Box sx={{ mt: 5 }}>{uiCopy.GENERIC_HELP_MESSAGE()}</Box>
            </Container>
          )}

          {screen === Screens.UnderstandingEmotions ? (
            <EmpathyController
              sectionId={1}
              sectionName={Screens.UnderstandingEmotions}
              introTitle={uiCopy.EMPATHY_UNDERSTAND_EMOTIONS_INTRO_TITLE}
              introBody={uiCopy.EMPATHY_UNDERSTAND_EMOTIONS_INTRO_BODY}
              load={async () => await empathyUnderstandEmotionsCreate()}
              save={async answers => await empathyUnderstandEmotionsFinish(email, cohort, answers)}
              onEnd={nextScreen}
              cohort={cohort}
            />
          ) : null}

          {screen === Screens.EmotionMatching ? (
            <EmpathyController
              sectionId={2}
              sectionName={Screens.EmotionMatching}
              introTitle={uiCopy.EMPATHY_MATCHING_EMOTION_INTRO_TITLE}
              introBody={uiCopy.EMPATHY_MATCHING_EMOTION_INTRO_BODY}
              load={async () => await empathyMatchingEmotionCreate()}
              save={async answers => await empathyMatchingEmotionFinish(email, cohort, answers)}
              onEnd={nextScreen}
              cohort={cohort}
            />
          ) : null}

          {screen === Screens.ImplementEmotions ? (
            <EmpathyController
              sectionId={3}
              sectionName={Screens.ImplementEmotions}
              introTitle={uiCopy.EMPATHY_IMPLEMENT_EMOTIONS_INTRO_TITLE}
              introBody={uiCopy.EMPATHY_IMPLEMENT_EMOTIONS_INTRO_BODY}
              load={async () => await empathyImplementEmotionsCreate()}
              save={async answers => await empathyImplementEmotionsFinish(email, cohort, answers)}
              onEnd={nextScreen}
              cohort={cohort}
            />
          ) : null}

          {screen === Screens.Explanation ? (
            <React.Fragment>
              <Container
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: 170,
                    height: 170,
                    svg: {
                      width: "100%",
                      height: "auto",
                    },
                  }}
                >
                  <IllustrationActivity />
                </Box>
                <Heading
                  as="h1"
                  sx={{
                    fontSize: [2, 3],
                    fontWeight: "bold",
                    textAlign: "center",
                    mb: 10,
                  }}
                >
                  What’s required from you
                </Heading>
                <Narrative>
                  This part takes approximately 20 minutes to complete.
                  <br />
                  Please make sure you will not be interrupted for the duration of the test, that
                  you are in a quiet space and that you are free from distractions (like mobile
                  phones).
                </Narrative>
                <Box sx={{ mt: 10 }} />
                <Button onClick={nextScreen}>Next</Button>
              </Container>
            </React.Fragment>
          ) : null}

          {screen === Screens.Booking && (
            <React.Fragment>
              <Container
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: 0,
                }}
              >
                <Box
                  sx={{
                    width: 170,
                    height: 170,
                    svg: {
                      width: "100%",
                      height: "auto",
                    },
                  }}
                >
                  <IllustrationSchedule />
                </Box>
                <Heading
                  as="h1"
                  sx={{
                    fontSize: [2, 3],
                    fontWeight: "bold",
                    textAlign: "center",
                    mb: 10,
                  }}
                >
                  Next Step
                </Heading>
                <Narrative>
                  <p>You have completed part 2 of the Spark Check.</p>
                  <p>Now, book a call with us to proceed to the next step of the process.</p>
                </Narrative>
                <Box sx={{ mt: 10 }}>
                  <ButtonAnchor href={bookingLink}>Book A Call</ButtonAnchor>
                </Box>
              </Container>
            </React.Fragment>
          )}

          {screen === Screens.End && (
            <React.Fragment>
              <Container
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: 170,
                    height: 170,
                    svg: {
                      width: "100%",
                      height: "auto",
                    },
                  }}
                >
                  <IllustrationPerson />
                </Box>
                <Heading
                  as="h1"
                  sx={{
                    fontSize: [2, 3],
                    fontWeight: "bold",
                    textAlign: "center",
                    mb: 10,
                  }}
                >
                  Congratulations!
                </Heading>
                <Narrative>
                  <p>You have completed the second part of the Spark Check.</p>
                  <p>You will hear back from us via email within the next few days.</p>
                </Narrative>
              </Container>
            </React.Fragment>
          )}
        </ContainerSmall>
      </Layout>
    </ThemeProvider>
  );
};
