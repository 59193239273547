import {
  User,
  UserTokenData,
  UpdateUserAttributes,
  PublicUserAttrs,
  UserExtraInfo,
} from "@sparkademy/app-common/models/user";
import { Level, Track, LevelStatus, ProjectInput } from "@sparkademy/app-common/models/level";
import { createProjectModule, Module } from "@sparkademy/app-common/models/module";
import { AssignmentType, UploadedFile } from "@sparkademy/app-common/models/assignment";
import { UserStatus } from "@sparkademy/app-common/models/user-status";
import { API_URL } from "@sparkademy/app-common/constants";
import { postWithRetry, getWithRetry } from "@sparkademy/app-common/utils/httpRetry";
import { setCookie } from "@sparkademy/app-common/utils/cookie";
import { ProfileAssessmentResult } from "../models/profile-assessment";
import { EmpathyItemAnswer, EmpathyItem, EmpathyTestStatus } from "../models/empathy";
import { FourSquaresRoundPayload } from "../models/four-squares";
import jwtDecode from "jwt-decode";
import ky, { Options as KyOptions } from "ky";
import pMemoize from "p-memoize";
import * as Sentry from "@sentry/browser";
import {
  QuestionAnswer,
  BlockUnitCompletionMap,
  ResumeCourseData,
} from "@sparkademy/app-common/models/course";
import { Logger } from "@sparkademy/app-common/services/logger-service";

export class HTTPError extends Error {}

const AUTH_COOKIE = "auth_token";

type StatusResponse = {
  faqId: string;
  current_cohort_id: string;
  levels: Array<{
    id: string;
    status: LevelStatus;
    failureType: "no-program" | "no-certification";
    project?: ProjectInput;
    modules: Array<{
      index: number;
      id: string;
      status:
        | "closed"
        | "open"
        | "completed"
        | "failed"
        | "failed-timeout"
        | "failed-retries-expired"
        | "locked";
      materials: {
        end: string;
        start: string;
        status: "closed" | "open" | "completed" | "completed-verified";
        url?: string;
        deadlineApproaching?: boolean;
      };
      assessment: {
        end: string;
        start: string;
        status: "closed" | "open" | "pending-evaluation" | "completed";
        url?: string;
        deadlineApproaching?: boolean;
      };
      hw: {
        end: string;
        start: string;
        status: "closed" | "open" | "pending-evaluation" | "completed";
        url?: string;
        deadlineApproaching?: boolean;
      };
      feedback: {
        url?: string;
        status: "open" | "started" | "completed";
      };
      feedbackUrl: string;
      workbookUrl: string;
      retry: boolean;
    }>;
  }>;
};

type ModuleResponse = {
  index: number;
  id: string;
  levelId: string;
  status: "closed" | "open" | "completed" | "failed" | "locked";
  materials: {
    end: string;
    start: string;
    status: "closed" | "open" | "completed";
    url?: string;
  };
  assessment: {
    end: string;
    start: string;
    status: "closed" | "open" | "pending-evaluation" | "completed";
    url?: string;
  };
  hw: {
    end: string;
    start: string;
    status: "closed" | "open" | "pending-evaluation" | "completed";
    url?: string;
    deadlineApproaching?: boolean;
  };
  feedback: {
    url?: string;
    status: "open" | "started" | "completed";
  };
  workbookUrl: string;
  retry: boolean;
};

const DEFAULT_OPTIONS: KyOptions = {
  retry: {
    limit: 5,
    methods: ["get", "put", "head", "delete", "options", "trace"],
    statusCodes: [408, 413, 429, 500, 502, 503, 504],
  },
};

function getAnalyticsId(): string | null {
  const analytics_user =
    window.analytics &&
    window.analytics.user &&
    typeof window.analytics.user === "function" &&
    window.analytics.user();
  return (analytics_user && analytics_user.anonymousId()) || null;
}

function getStatusForTokenAndData(
  token: string,
  analyticsId: string | null,
  date: Date
): Promise<StatusResponse> {
  let url = `${API_URL}/status?token=${token}`;
  if (analyticsId) {
    url = `${url}&analytics_id=${encodeURIComponent(analyticsId)}`;
  }
  const showDate = Boolean(sessionStorage.getItem("showDate"));
  if (showDate) {
    const dateString = date.toISOString().slice(0, 10);
    url = `${url}&test_date=${encodeURIComponent(dateString)}`;
  }

  return ky.get(url, { ...DEFAULT_OPTIONS }).json() as Promise<StatusResponse>;
}

const memoGetStatusForTokenAndData = pMemoize(getStatusForTokenAndData, { maxAge: 5000 });

export async function login(email: string, next?: string, src?: string): Promise<void> {
  let url = `${API_URL}/auth/login?email=${encodeURIComponent(email)}`;
  if (next) {
    url += `&next=${encodeURIComponent(next || "")}`;
  }
  if (src) {
    url += `&src=${src}`;
  }
  return ky.get(url, { ...DEFAULT_OPTIONS }).json();
}

export async function signup(
  firstName: string,
  lastName: string,
  email: string,
  courseRunId: string | null
): Promise<{ id?: string; error?: string }> {
  const payload = {
    first_name: firstName,
    last_name: lastName,
    course_run_id: courseRunId,
    email,
  };
  const res = await ky.post(`${API_URL}/auth/signup`, {
    ...DEFAULT_OPTIONS,
    json: payload,
  });
  return res.json();
}

export async function authenticate(token: string): Promise<User> {
  await ky.get(`${API_URL}/status?token=${token}`, { ...DEFAULT_OPTIONS }).json();

  setCookie(AUTH_COOKIE, token, 180);

  try {
    const tokenData: UserTokenData = jwtDecode(token);

    setCookie(AUTH_COOKIE, token, 180);

    const user: User = {
      company: tokenData.company,
      role: tokenData.role,
      username: tokenData.firstName,
      jwt: token,
      exp: tokenData.exp,
      data: tokenData,
      b2cUser: tokenData.cohort_id.includes("courserun"),
    };

    const userAttrs: Record<string, string> = {
      cohort: user.data.cohort_id,
    };

    const APP_ID = "w00qa0p7";

    // TODO do we keep this or remove it?
    window.analytics &&
      user.data.id &&
      window.analytics.identify(user.data.id, {
        name: user.data.firstName + " " + user.data.lastName,
        email: user.data.email,
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        role: user.data.role,
        company: {
          id: user.company,
          name: user.company,
        },
        ...userAttrs,
      });

    window.Intercom &&
      window.Intercom("boot", {
        app_id: APP_ID,
        email: user.data.email,
        user_id: user.data.id,
        name: user.data.firstName + " " + user.data.lastName,
        role: user.data.role,
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        company: {
          company_id: user.company,
          company_name: user.company,
        },
        "cohort_id": user.data.cohort_id,
        custom_attributes: userAttrs,
      });

    Sentry.configureScope(scope => {
      scope.setUser({
        email: user.data.email,
      });
    });

    return user;
  } catch (ex) {
    const user: User = {
      company: "Test",
      role: "learner",
      username: "Alan",
      jwt: token,
      exp: new Date().getTime() / 1000 + 864000,
      data: {
        cohort_id: "unit_test",
        company: "",
        email: "",
        exp: new Date().getTime() / 1000 + 864000,
        firstName: "Alan",
        lastName: "",
        role: "learner",
        id: "ea0477b1-e4c8-40d3-9b36-b9d733a33ae5",
      },
    };
    return user;
  }
}

export async function getStatus(user: User): Promise<UserStatus | null> {
  try {
    const savedData = sessionStorage.getItem("dateRef");

    const date = savedData ? new Date(savedData) : new Date();
    const analyticsId = getAnalyticsId();

    const parsed: StatusResponse = await memoGetStatusForTokenAndData(user.jwt, analyticsId, date);
    const isOnRetry = !!parsed.levels
      .flatMap(l => l.modules)
      .find(m => m.status === "open" && m.retry === true);

    const status: UserStatus = {
      isOnRetry,
      faqId: parsed.faqId,
      currentCohortId: parsed.current_cohort_id,
      levels: parsed.levels.map(
        l =>
          new Level({
            id: l.id,
            failureType: l.failureType,
            status: l.status,
            project: l.project,
            modules: l.modules
              .map(
                m =>
                  new Module(
                    m.index,
                    m.id,
                    l.id,
                    m.workbookUrl,
                    m.status,
                    m.retry,
                    {
                      start: new Date(m.materials.start),
                      end: new Date(m.materials.end),
                      status: m.materials.status,
                      url: m.materials.url,
                      deadlineApproaching: m.materials.deadlineApproaching,
                    },
                    {
                      start: new Date(m.assessment.start),
                      end: new Date(m.assessment.end),
                      status: m.assessment.status,
                      url: m.assessment.url || "#",
                      deadlineApproaching: m.assessment.deadlineApproaching,
                    },
                    {
                      start: new Date(m.hw.start),
                      end: new Date(m.hw.end),
                      status: m.hw.status,
                      url: m.hw.url || "#",
                      deadlineApproaching: m.hw.deadlineApproaching,
                    },
                    {
                      url: m.feedback.url || "#",
                      status: m.feedback.status,
                    }
                  )
              )
              .concat(
                l.project
                  ? createProjectModule(l.project.module_id, l.modules.length, true, l.id)
                  : []
              ),
          })
      ),
    };
    return status;
  } catch (err) {
    Logger.error(err);
    return null;
  }
}

export function updateUserAttributes(attributes: UpdateUserAttributes, user: User) {
  ky.post(
    `${API_URL}/user/setattributes/?timezoneoffset=${encodeURIComponent(
      attributes.timeZoneOffset
    )}&timezonename=${encodeURIComponent(attributes.timeZoneName)}&token=${user.jwt}`
  );
}

export function getCalendarUrlForLevel(level: Level, user: User) {
  return `${API_URL}/calendar/?level=${level.id}&token=${user.jwt}`;
}

export function getCertificateUrlForLevel(levelId: string, user: User) {
  return `${API_URL}/certificates/download?level=${levelId}&token=${user.jwt}`;
}

export async function addCertificateToLinkedIn(
  levelId: string,
  user: User
): Promise<{ url: string }> {
  return ky
    .get(`${API_URL}/certificates/add_to_linkedin?level=${levelId}&token=${user.jwt}`)
    .json();
}

export async function shareCertificateOnLinkedIn(
  level: Level,
  user: User
): Promise<{ url: string }> {
  return ky
    .get(`${API_URL}/certificates/share_on_linkedin?level=${level.id}&token=${user.jwt}`)
    .json();
}

export function selectTrack(level: Level, track: Track, user: User) {
  return Promise.resolve();
}

export async function fourSquaresCreate(
  trials: number[],
  userAttrs: PublicUserAttrs,
  cohort: string
): Promise<{ id?: string; error?: string }> {
  const analyticsId = getAnalyticsId();
  const res = await postWithRetry(`${API_URL}/four-squares/new/${userAttrs.email}`, {
    trials,
    cohort_id: cohort,
    analytics_id: analyticsId,
    first_name: userAttrs.firstName,
    last_name: userAttrs.lastName,
  });
  return res.json();
}
export async function fourSquaresUpdate(
  round: FourSquaresRoundPayload,
  email: string,
  cohort: string
) {
  await postWithRetry(`${API_URL}/four-squares/update/${email}`, {
    round_data: round,
    cohort_id: cohort,
  });
}

export function empathyTestStatus(email: string, cohortId: string): Promise<EmpathyTestStatus> {
  return ky
    .get(
      `${API_URL}/empathy_test/status?email=${encodeURIComponent(email)}&cohort_id=${cohortId}`,
      {
        ...DEFAULT_OPTIONS,
      }
    )
    .json() as Promise<EmpathyTestStatus>;
}

export function empathyMatchingEmotionCreate(): Promise<EmpathyItem[]> {
  return ky
    .get(`${API_URL}/empathy_test/emotion_matching/section_items`, {
      ...DEFAULT_OPTIONS,
    })
    .json() as Promise<EmpathyItem[]>;
}
export async function empathyMatchingEmotionFinish(
  email: string,
  cohort: string,
  answers: EmpathyItemAnswer[]
): Promise<{ id: string }> {
  const analyticsId = getAnalyticsId();
  const res = await postWithRetry(`${API_URL}/empathy_test/answers`, {
    userEmail: email,
    userCohort: cohort,
    sectionItems: answers,
    analytics_id: analyticsId,
  });
  return res.json();
}

export function empathyUnderstandEmotionsCreate(): Promise<EmpathyItem[]> {
  return ky
    .get(`${API_URL}/empathy_test/understanding_emotions/section_items`, {
      ...DEFAULT_OPTIONS,
    })
    .json() as Promise<EmpathyItem[]>;
}
export async function empathyUnderstandEmotionsFinish(
  email: string,
  cohort: string,
  answers: EmpathyItemAnswer[]
): Promise<{ id: string }> {
  const analyticsId = getAnalyticsId();
  const res = await postWithRetry(`${API_URL}/empathy_test/answers`, {
    userEmail: email,
    userCohort: cohort,
    sectionItems: answers,
    analytics_id: analyticsId,
  });
  return res.json();
}

export function empathyImplementEmotionsCreate(): Promise<EmpathyItem[]> {
  return Promise.resolve([]);
}
export function empathyImplementEmotionsFinish(
  email: string,
  cohort: string,
  answers: EmpathyItemAnswer[]
): Promise<{ id: string }> {
  return Promise.resolve({ id: "" });
}

export async function getProfileAssessmentResults(id: string): Promise<ProfileAssessmentResult> {
  return ky
    .get(`${API_URL}/profile/results/${id}`, {
      ...DEFAULT_OPTIONS,
    })
    .json() as Promise<ProfileAssessmentResult>;
}

export async function listAssignmentUploads(
  moduleId: string,
  assignmentType: AssignmentType,
  user: User
): Promise<{ files: UploadedFile[] }> {
  return ky
    .get(`${API_URL}/assignments/list_uploads/${moduleId}/${assignmentType}?token=${user.jwt}`, {
      ...DEFAULT_OPTIONS,
    })
    .json() as Promise<{ files: UploadedFile[] }>;
}

export async function publicMethodKitLogin(
  email: string,
  ref?: string,
  companyId?: string,
  next?: string
): Promise<void> {
  let url = `${API_URL}/auth/method_kit/login?email=${encodeURIComponent(email.toLowerCase())}`;
  if (ref) {
    url = `${url}&ref=${encodeURIComponent(ref)}`;
  }
  if (companyId) {
    url = `${url}&company_id=${encodeURIComponent(companyId)}`;
  }
  if (next) {
    url = `${url}&next=${encodeURIComponent(next)}`;
  }

  const analyticsId = getAnalyticsId();
  if (analyticsId) {
    url = `${url}&analytics_id=${encodeURIComponent(analyticsId)}`;
  }

  await ky.post(url, DEFAULT_OPTIONS);
}
export async function validateClientToken(token: string): Promise<void> {
  await ky.post(`${API_URL}/auth/validate_client_token?token=${token}`, DEFAULT_OPTIONS);
}

export function oAuthAuthorizeUrl(clientId: string, user: User): string {
  return `${API_URL}/oauth/authorize?response_type=code&client_id=${clientId}&scope=profile&token=${user.jwt}`;
}

export async function saveQuizAnswer(
  cohortId: string,
  courseId: string,
  lessonId: string,
  lessonBlockId: string,
  lessonBlockUnitId: string,
  quizContentId: string,
  questionId: string,
  questionIndex: number,
  optionIndex: number,
  optionWeight: number,
  user: User
): Promise<{ id: string }> {
  const res = await postWithRetry(`${API_URL}/quiz/answers?token=${user.jwt}`, {
    "cohort_id": cohortId,
    "course_id": courseId,
    "lesson_id": lessonId,
    "lesson_block_id": lessonBlockId,
    "lesson_block_unit_id": lessonBlockUnitId,
    "quiz_content_id": quizContentId,
    "question_id": questionId,
    "question_index": questionIndex,
    "option_index": optionIndex,
    "option_weight": optionWeight,
  });
  return res.json();
}

export async function fetchQuizAnswers(
  quizContentId: string,
  user: User
): Promise<QuestionAnswer[]> {
  const res = await getWithRetry(
    `${API_URL}/quiz/answers?quiz_content_id=${quizContentId}&token=${user.jwt}`
  );
  const data = await (res.json() as Promise<{ question_id: string; option_index: number }[]>);
  return data.map((answer: { question_id: string; option_index: number }) => ({
    questionId: answer.question_id,
    index: answer.option_index,
  }));
}

export async function saveKnowledgeCheckAnswers(
  cohortId: string,
  courseId: string,
  lessonId: string,
  lessonBlockId: string,
  lessonBlockUnitId: string,
  knowledgeCheckContentId: string,
  moduleId: string,
  pageNumber: number,
  answers: {
    questionGroupId?: string;
    questionId: string;
    questionIndex: number;
    maxScore: number;
    optionIndex: number;
    optionWeight: number;
  }[],
  submit: boolean,
  user: User
): Promise<{ id: string }> {
  const url = submit
    ? `${API_URL}/knowledge_check/answers?token=${user.jwt}&submit=1`
    : `${API_URL}/knowledge_check/answers?token=${user.jwt}`;

  const res = await postWithRetry(url, {
    "cohort_id": cohortId,
    "course_id": courseId,
    "lesson_id": lessonId,
    "lesson_block_id": lessonBlockId,
    "lesson_block_unit_id": lessonBlockUnitId,
    "knowledge_check_id": knowledgeCheckContentId,
    "module_id": moduleId,
    "page_number": pageNumber,
    "answers": answers.map(a => ({
      "question_group_id": a.questionGroupId,
      "question_id": a.questionId,
      "question_index": a.questionIndex,
      "max_score": a.maxScore,
      "option_index": a.optionIndex,
      "weight": a.optionWeight,
    })),
  });
  return res.json();
}

export async function fetchKnowledgeCheckAnswers(
  knowledgeCheckContentId: string,
  user: User
): Promise<QuestionAnswer[]> {
  const res = await getWithRetry(
    `${API_URL}/knowledge_check/answers?knowledge_check_id=${knowledgeCheckContentId}&token=${user.jwt}`
  );
  const data = await (res.json() as Promise<{ question_id: string; option_index: number }[]>);
  return data.map((answer: { question_id: string; option_index: number }) => ({
    questionId: answer.question_id,
    index: answer.option_index,
  }));
}

//? Refactor arguments into object? Then pass object directly
export async function saveBlockUnitCompletion(
  cohortId: string,
  courseId: string,
  lessonId: string,
  lessonBlockId: string,
  lessonBlockUnitId: string,
  lessonIndex: number,
  lessonBlockIndex: number,
  lessonBlockUnitIndex: number,
  user: User
): Promise<Response> {
  const res = await postWithRetry(`${API_URL}/course/block_unit_completion?token=${user.jwt}`, {
    cohort_id: cohortId,
    course_id: courseId,
    lesson_id: lessonId,
    lesson_block_id: lessonBlockId,
    lesson_block_unit_id: lessonBlockUnitId,
    lesson_index: lessonIndex,
    lesson_block_index: lessonBlockIndex,
    lesson_block_unit_index: lessonBlockUnitIndex,
  });

  return res;
}

export async function getBlockUnitCompletions(
  courseId: string,
  user: User
): Promise<BlockUnitCompletionMap> {
  const res = await getWithRetry(
    `${API_URL}/course/block_unit_completion?course_id=${courseId}&token=${user.jwt}`
  );

  const completions = await (res.json() as Promise<
    { lesson_id: string; lesson_block_id: string; lesson_block_unit_id: string }[]
  >);

  return completions.reduce((previous, current) => {
    if (!(current.lesson_id in previous)) {
      previous[current.lesson_id] = {
        [current.lesson_block_id]: [parseInt(current.lesson_block_unit_id)],
      };
    } else if (!(current.lesson_block_id in previous[current.lesson_id])) {
      previous[current.lesson_id][current.lesson_block_id] = [
        parseInt(current.lesson_block_unit_id),
      ];
    } else {
      previous[current.lesson_id][current.lesson_block_id].push(
        parseInt(current.lesson_block_unit_id)
      );
    }
    return previous;
  }, {} as BlockUnitCompletionMap);
}

export async function getResumeCourseData(courseId: string, user: User): Promise<ResumeCourseData> {
  const res = await getWithRetry(`${API_URL}/course/resume/${courseId}?token=${user.jwt}`);
  const data = await (res.json() as Promise<{
    lesson_id: number;
    lesson_block_id: number;
    lesson_block_unit_id: number;
  }>);

  return {
    lessonId: data.lesson_id,
    lessonBlockId: data.lesson_block_id,
    lessonBlockUnitId: data.lesson_block_unit_id,
  };
}

export async function enrollUserIntoWorkshopSession(session_id: string, user: User) {
  const res = await postWithRetry(
    `${API_URL}/workshops/session/${session_id}/enroll/?token=${user.jwt}`,
    undefined,
    3,
    { timeout: 15_000 }
  );
  return res.json() as Promise<{
    "enrollment_id": undefined | number;
    "enrollment_count": undefined | number;
    "error": undefined | string;
  }>;
}

export async function enrollUserIntoCourseRuns(courseRunId: number, user: User) {
  const res = await postWithRetry(
    `${API_URL}/course_run/enrollments?token=${user.jwt}`,
    { course_run_id: courseRunId },
    3,
    {
      timeout: 15_000,
    }
  );
  return res;
}

export async function unenrollUserFromWorkshopSession(session_id: string, user: User) {
  const res = await postWithRetry(
    `${API_URL}/workshops/session/${session_id}/unenroll/?token=${user.jwt}`,
    undefined,
    3,
    { timeout: 15_000 }
  );
  return res;
}

export async function getEnrolledModules(user: User, date: Date): Promise<Module[]> {
  const res = await getWithRetry(
    `${API_URL}/course_run/enrollments/modules?token=${user.jwt}&date=${date
      .toISOString()
      .slice(0, 10)}`
  );
  const data = await (res.json() as Promise<ModuleResponse[]>);
  return data.map(
    m =>
      new Module(
        m.index,
        m.id,
        m.levelId,
        m.workbookUrl,
        m.status,
        m.retry,
        {
          start: new Date(m.materials.start),
          end: new Date(m.materials.end),
          status: m.materials.status,
          url: m.materials.url,
        },
        {
          start: new Date(m.assessment.start),
          end: new Date(m.assessment.end),
          status: m.assessment.status,
          url: m.assessment.url || "#",
        },
        {
          start: new Date(m.hw.start),
          end: new Date(m.hw.end),
          status: m.hw.status,
          url: m.hw.url || "#",
        },
        {
          url: m.feedback.url || "#",
          status: m.feedback.status,
        },
        false,
        true
      )
  );
}

export async function addUserExtraInfo(userId: string, data: UserExtraInfo) {
  const res = await postWithRetry(`${API_URL}/user/setextrainfo`, {
    "user_id": userId,
    "pronoun": data.pronoun,
    "job_role": data.jobRole,
    "responsibility": data.responsibility,
    "occupation": data.occupation,
    "country": data.country,
    "referral_source": data.referralSource,
    "educational_background": data.educationalBackground,
    "professional_background": data.professionalBackground,
    "program_expectation": data.programExpectation,
    "program_motivation": data.programMotivation,
  });
  return res;
}
