import * as React from "react";
import { Textarea, Box, Text } from "theme-ui";
import {
  AnswersMap,
  ExerciseSection,
  ExerciseStatusType,
  GradingFeedbackMap,
  Question,
} from "@sparkademy/app-common/models/assignment";
import { useAssignmentContext } from "@sparkademy/app-common/contexts/assignment-context";
import { useAssignmentAnswersContext } from "@sparkademy/app-common/contexts/assignment-answers-context";
import { OpenAiService } from "@sparkademy/app-common/services/openai-service";
import { useAtom } from "jotai";
import { useChatGPTAtom } from "@sparkademy/app-common/stores/grading";

type ExpandableTextAreaElement = HTMLTextAreaElement & { _baseScrollHeight: number };

export const FeedbackInputText: React.FC<{
  evaluationItem: Question | ExerciseSection;
  exerciseId: string;
  feedbackIndex: number;
  text: string;
}> = ({ evaluationItem, exerciseId, feedbackIndex, text }) => {
  // cast evaluationItem to Question or ExerciseSection

  const inputRef = React.useRef<ExpandableTextAreaElement>(null);
  const [useChatGPT] = useAtom(useChatGPTAtom);

  const { savedFeedback, updateFeedback, savedAnswers } = useAssignmentAnswersContext();
  const { assignmentStatus } = useAssignmentContext();
  const exerciseStatus = assignmentStatus.find(as => as.exercise_id === exerciseId);
  const wasGraded = exerciseStatus?.status === ExerciseStatusType.GRADED;

  const [value, setValue] = React.useState(text);
  const [aiEnhancedValue, setAiEnhancedValue] = React.useState("");
  const itemFeedback = savedFeedback[evaluationItem.id];

  React.useEffect(() => {
    if (itemFeedback) {
      setValue(itemFeedback[feedbackIndex]);
    }
  }, [savedFeedback]);

  React.useEffect(() => {
    if (inputRef.current) {
      onExpandableTextareaInput(inputRef.current);
    }
  }, [inputRef.current]);

  const shouldEnhanceFeedback = itemFeedback.length > 0;

  React.useEffect(() => {
    if (!useChatGPT) return;
    if (aiEnhancedValue !== "") return;

    if (shouldEnhanceFeedback) {
      getAIEnhancedFeedback(evaluationItem, savedAnswers, savedFeedback).then(res => {
        setAiEnhancedValue(res.trim());
      });
    }
  }, [useChatGPT, evaluationItem, savedAnswers, savedFeedback, aiEnhancedValue]);

  const onChangeHandler = (val: string) => {
    setValue(val);
    updateFeedback(evaluationItem.id, feedbackIndex, val);
  };

  const getScrollHeight = (el: ExpandableTextAreaElement) => {
    const savedValue = el.value;
    el.value = "";
    el._baseScrollHeight = el.scrollHeight;
    el.value = savedValue;
  };

  const onExpandableTextareaInput = (el: ExpandableTextAreaElement) => {
    if (el === null) {
      return;
    }

    const minRows = parseInt(el.getAttribute("data-min-rows") as string);
    !el._baseScrollHeight && getScrollHeight(el);

    el.rows = minRows;
    const rows = Math.ceil((el.scrollHeight - el._baseScrollHeight) / 22);
    el.rows = minRows + rows;
  };

  if (wasGraded) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bg: "#EDE9FC",
          py: "32px",
          px: "48px",
          borderRadius: "5px",
          my: "32px",
        }}
      >
        <Text as="p" sx={{ fontWeight: 700, fontSize: "16px", mb: "32px" }}>
          Feedback
        </Text>
        <Text
          as="pre"
          sx={{
            fontFamily: "inherit",
            fontSize: "16px",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          {value}
        </Text>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Textarea
        ref={inputRef}
        sx={{
          border: "2px solid",
          borderColor: "new.secondary.grey",
          fontFamily: "inherit",
          fontSize: "16px",
          lineHeight: "22px",
          mb: "32px",
          py: "32px",
          px: "48px",
          outline: "none",
          ":focus": {
            borderColor: "primary",
          },
        }}
        rows={3}
        data-min-rows="3"
        value={value}
        onChange={e => onChangeHandler(e.target.value)}
        onInput={ev => onExpandableTextareaInput(ev.target as ExpandableTextAreaElement)}
      />
      {useChatGPT && shouldEnhanceFeedback && (
        <Text
          as="pre"
          sx={{
            fontFamily: "inherit",
            fontSize: "16px",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
            bg: "#EDE9FC",
            p: "15px",
          }}
        >
          <b>[SUGGESTION FROM CHATGPT]</b>
          <br />
          <br />
          {aiEnhancedValue || "Loading..."}
        </Text>
      )}
    </Box>
  );
};

async function getAIEnhancedFeedback(
  evaluationItem: Question | ExerciseSection,
  savedAnswers: AnswersMap,
  savedFeedback: GradingFeedbackMap
): Promise<string> {
  let prompt = evaluationItem.gpt_query || "";
  prompt = replaceAnswersPlaceholders(savedAnswers, prompt);
  prompt = replaceFeedbackPlaceholders(savedFeedback, prompt);

  console.log(`===== CHATGPT PROMPT FOR ${evaluationItem.id} =====\n\n`, prompt);

  const data = await OpenAiService.createChatCompletion(prompt);
  return data.choices[0].message.content;
}

function replaceAnswersPlaceholders(savedAnswers: AnswersMap, str: string) {
  for (const [key, value] of savedAnswers) {
    if (typeof value !== "string") continue;
    // placeholder format: {{1.1.a_answer}}
    str = str.replace(`{{${key}_answer}}`, value || "[blank]");
  }
  return str;
}

function replaceFeedbackPlaceholders(savedFeedback: GradingFeedbackMap, str: string) {
  for (const [key, value] of Object.entries(savedFeedback)) {
    let feedback = "[blank]";
    if (Array.isArray(value) && value.length > 0) {
      feedback = value[0];
    }
    // placeholder format: {{1.1.a_feedback}}
    str = str.replace(`{{${key}_feedback}}`, feedback);
  }
  return str;
}
