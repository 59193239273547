import ky from "ky";
import { getWithRetry, postFormWithRetry, postWithRetry } from "../utils/httpRetry";
import {
  AnswersMap,
  AssignmentType,
  UploadedFile,
  ExerciseStatus,
  Assignment,
  ExerciseSubmission,
  SavedExercise,
  ExerciseTeam,
  Teammate,
} from "../models/assignment";
import { API_URL } from "../constants";
import { User } from "../models/user";

const removeTestTeammates = (teammates: Teammate[]) => {
  // test patterns
  // testy, testing, test, gmail.com
  return teammates.filter(teammate => !teammate.email.match(/(testy|testing|test|gmail\.com)/));
};

export class AssignmentService {
  static async getModuleAssignment(
    moduleId: string,
    cohortId: string,
    user: User,
    retry: boolean | null = false
  ): Promise<Assignment> {
    let url = cohortId
      ? `${API_URL}/assignments/cohort/${cohortId}/${moduleId}`
      : `${API_URL}/assignments/courserun/${moduleId}`;

    url += `?token=${user.jwt}`;

    if (retry !== null) {
      url += `&retry=${retry ? 1 : 0}`;
    }

    const resp = await getWithRetry(url);
    return (await resp.json()) as Promise<Assignment>;
  }

  static async uploadAssignmentFiles(
    questionId: string,
    moduleId: string,
    assignmentType: AssignmentType,
    isRetry: boolean,
    files: FileList,
    user: User
  ): Promise<{ files?: UploadedFile[]; error?: string }> {
    const formData = new FormData();
    formData.append("question_id", questionId);
    formData.append("module_id", moduleId);
    formData.append("assignment_type", assignmentType);
    formData.append("is_retry", isRetry ? "1" : "0");

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append("files[]", file, file.name);
    }

    const res = await postFormWithRetry(
      `${API_URL}/assignments/upload?token=${user.jwt}`,
      formData,
      5,
      { timeout: 30_000 }
    );

    return res.json() as Promise<{ files: UploadedFile[]; error: string }>;
  }

  static async deleteAssignmentUpload(assignmentUploadId: number, user: User): Promise<void> {
    ky.delete(`${API_URL}/assignments/delete_upload/${assignmentUploadId}?token=${user.jwt}`);
  }

  static async getExerciseAnswers(
    assignmentExerciseId: number,
    user: User
  ): Promise<SavedExercise> {
    const resp = await getWithRetry(
      `${API_URL}/assignment/answers/${assignmentExerciseId}?token=${user.jwt}`
    );
    const savedExercise = await ((await resp.json()) as Promise<SavedExercise>);
    return {
      ...savedExercise,
      submitted_at: savedExercise.submitted_at ? new Date(savedExercise.submitted_at) : undefined,
      graded_at: savedExercise.graded_at ? new Date(savedExercise.graded_at) : undefined,
      grading_lock_expires_at: savedExercise.grading_lock_expires_at
        ? new Date(savedExercise.grading_lock_expires_at)
        : undefined,
    };
  }

  static async getUserAssignmentStatus(
    moduleId: string,
    user: User,
    retry: boolean | null
  ): Promise<ExerciseStatus[]> {
    const resp = await getWithRetry(
      retry !== null
        ? `${API_URL}/assignments/module/${moduleId}/status?token=${user.jwt}&retry=${
            retry ? 1 : 0
          }`
        : `${API_URL}/assignments/module/${moduleId}/status?token=${user.jwt}`
    );
    return (await resp.json()) as Promise<ExerciseStatus[]>;
  }

  static async getAssignmentSubmissions(
    cohortId: string,
    user: User
    // retry: boolean,
  ): Promise<ExerciseSubmission[]> {
    const resp = await getWithRetry(
      `${API_URL}/assignments/cohort/${cohortId}/submissions?token=${user.jwt}`
    );
    const submissions = (await resp.json()) as Promise<ExerciseSubmission[]>;
    return (await submissions).map(s => ({
      ...s,
      submitted_at: s.submitted_at ? new Date(s.submitted_at) : undefined,
      graded_at: s.graded_at ? new Date(s.graded_at) : undefined,
    }));
  }

  static async getFlaggedSubmissions(user: User): Promise<ExerciseSubmission[]> {
    const resp = await getWithRetry(`${API_URL}/assignments/flagged_submissions?token=${user.jwt}`);
    const submissions = (await resp.json()) as Promise<ExerciseSubmission[]>;
    return (await submissions).map(s => ({
      ...s,
      submitted_at: s.submitted_at ? new Date(s.submitted_at) : undefined,
      graded_at: s.graded_at ? new Date(s.graded_at) : undefined,
    }));
  }

  static async getAssignmentSubmission(
    submissionId: number,
    user: User
    // retry: boolean,
  ): Promise<ExerciseSubmission> {
    const resp = await getWithRetry(
      `${API_URL}/assignments/submission/${submissionId}?token=${user.jwt}`
    );
    const submission = await ((await resp.json()) as Promise<ExerciseSubmission>);
    return {
      ...submission,
      submitted_at: submission.submitted_at ? new Date(submission.submitted_at) : undefined,
      graded_at: submission.graded_at ? new Date(submission.graded_at) : undefined,
    };
  }

  static async submitExerciseAnswers(
    assignmentId: string,
    exerciseId: string,
    user: User
    // retry: boolean,
  ): Promise<void> {
    await postWithRetry(
      `${API_URL}/assignment/answers/${assignmentId}/submit?exercise_id=${exerciseId}&token=${user.jwt}`
    );
  }

  static async saveExerciseAnswers(
    assignmentId: string,
    exerciseId: string,
    answers: AnswersMap,
    user: User,
    assignmentExerciseId?: number,
    lastSavedAt?: Date,
    lastSavedBy?: string
  ): Promise<boolean> {
    if (answers.size === 0) return false;

    const payload = [];

    for (const [questionId, answer] of answers) {
      const questionIdParts = questionId.split(".");
      const questionExerciseId = "ex" + questionIdParts[0];
      if (questionExerciseId === exerciseId) {
        if (typeof answer === "string") {
          payload.push({
            question_id: questionId,
            text: answer,
          });
        } else if (answer.id) {
          // file upload
          payload.push({
            question_id: questionId,
            upload_id: answer.id,
          });
        }
      }
    }

    let baseUrl = `${API_URL}/assignment/answers/${assignmentId}/${exerciseId}/save?token=${user.jwt}`;
    if (assignmentExerciseId) {
      baseUrl += `&assignment_exercise_id=${assignmentExerciseId}`;
    }
    if (lastSavedAt && lastSavedBy) {
      baseUrl += `&last_saved_at=${lastSavedAt.getTime() / 1000}&last_saved_by=${lastSavedBy}`;
    }
    if (payload.length > 0) {
      await postWithRetry(baseUrl, payload);
    }
    return true;
  }

  static async getTeams(moduleId: string, user: User): Promise<ExerciseTeam[]> {
    const resp = await getWithRetry(`${API_URL}/teams/${moduleId}?token=${user.jwt}`);
    const json = (await resp.json()) as ExerciseTeam[];
    return json;
  }

  static async getAvailableTeammates(
    moduleId: string,
    exerciseId: string,
    user: User
  ): Promise<Teammate[]> {
    const resp = await getWithRetry(
      `${API_URL}/teams/available/${moduleId}/${exerciseId}?token=${user.jwt}`
    );

    const json = (await resp.json()) as Teammate[];

    const currentCohort = user.data.cohort_id;
    if (
      process.env.NODE_ENV === "development" ||
      currentCohort.includes("test") ||
      currentCohort.includes("demo") ||
      currentCohort.includes("temp")
    ) {
      return json;
    }

    return removeTestTeammates(json);
  }

  static async createTeam(
    assignmentId: string,
    moduleId: string,
    exerciseId: string,
    members: string[],
    user: User
  ): Promise<ExerciseTeam> {
    const resp = await postWithRetry(`${API_URL}/teams/create?token=${user.jwt}`, {
      module_id: moduleId,
      assignment_id: assignmentId,
      exercise_id: exerciseId,
      users: members,
    });
    const json = (await resp.json()) as ExerciseTeam;
    return json;
  }

  static async rateExerciseFeedback(
    assignmentExerciseId: number,
    rating: number,
    user: User
  ): Promise<void> {
    const resp = await postWithRetry(
      `${API_URL}/assignments/submission/${assignmentExerciseId}/review_grader?token=${user.jwt}`,
      {
        rating,
      }
    );
    return resp.json();
  }
}
