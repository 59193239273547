import * as React from "react";
import { Box, Text } from "theme-ui";
import { ReactComponent as AssessmentIcon } from "@sparkademy/app-common/materials/icons/new/assessment.svg";
import { Module } from "@sparkademy/app-common/models/module";
import { AssignmentType } from "@sparkademy/app-common/models/assignment";
import { dateFormatter } from "@sparkademy/app-common/utils/date-formatters";
import { UploadAssignmentsModal } from "../components/UploadAssignmentsModal";
import {
  ButtonGhost,
  ButtonGhostAnchor,
  ButtonGhostLink,
} from "@sparkademy/app-common/elements/ButtonGhost";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";

const PracticalAssignmentAsPdfMap: { [k: string]: string[] } = {
  "car_b1": ["m1", "m2", "m3", "m4"],
  "car_b2": ["m1", "m2", "m3", "m4"],
  "pmi_b6": ["m1", "m2", "m3", "m4"],
  "pmi_b7": ["m1", "m2"],
  "pmi_b1": ["m1"],
  "sie_b1": ["m1", "m2", "m3", "m4"],
  "unit_test": ["m1"],
};

export const OpenAssignmentButton: React.FC<{ url: string }> = ({ url }) => (
  <ButtonGhostAnchor href={url} sx={{ minHeight: "unset", p: 0 }}>
    Open
  </ButtonGhostAnchor>
);

export const ModuleAssignments: React.FC<{ module: Module }> = ({ module }) => {
  const columnProps = {
    py: 7,
    display: "flex",
    borderBottom: "0.5px solid",
    borderBottomColor: "new.secondary.grey",
    alignItems: "center",
  };

  if (!module.materials.url || !module.homework) {
    return null;
  }

  return (
    <Box
      sx={{
        mt: 8,
        borderTop: "1px solid",
        borderTopColor: "new.secondary.grey",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          fontWeight: "bold",
          ...columnProps,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "30%" }}>
          <Box sx={{ mr: 3 }}>
            <AssessmentIcon />
          </Box>
          Assignments
        </Box>
        <Box sx={{ width: "20%" }}>Due</Box>
        <Box sx={{ width: "50%" }}>Status</Box>
      </Box>

      <Box sx={columnProps}>
        <Box sx={{ width: "30%" }}>Practical Assignment</Box>
        <Box sx={{ width: "20%" }}>{dateFormatter.format(module.homework.end)}</Box>
        <Box id="pratical-assignment-status" sx={{ width: "50%", fontWeight: 500 }}>
          <HomeworkStatus module={module} />
        </Box>
      </Box>

      <Box sx={columnProps}>
        <Box sx={{ width: "30%" }}>Knowledge Check</Box>
        <Box sx={{ width: "20%" }}>{dateFormatter.format(module.assessment.end)}</Box>
        <Box id="knowledge-check-status" sx={{ width: "50%", fontWeight: 500 }}>
          <AssessmentStatus module={module} />
        </Box>
      </Box>

      {module.id !== "m0" && (
        <Box sx={columnProps}>
          <Box sx={{ width: "30%" }}>Course Evaluation</Box>
          <Box sx={{ width: "20%" }}>{dateFormatter.format(module.assessment.end)}</Box>
          <Box id="course-evaluation-status" sx={{ width: "50%", fontWeight: 500 }}>
            <CourseEvaluationStatus module={module} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const HomeworkStatus: React.FC<{ module: Module }> = ({ module }) => {
  const [isUploadFilesModalOpen, setIsUploadFilesModalOpen] = React.useState<boolean>(false);
  const { currentUser } = useSessionContext();
  const cohortId = currentUser?.data.cohort_id || "";

  const OpenAssignmentsButton =
    cohortId in PracticalAssignmentAsPdfMap &&
    PracticalAssignmentAsPdfMap[cohortId].includes(module.id) ? (
      // previous version of the PA
      <>
        <ButtonGhost
          className="open-pa-btn"
          onClick={() => setIsUploadFilesModalOpen(true)}
          sx={{ minHeight: "unset", p: 0 }}
        >
          Open
        </ButtonGhost>
        <UploadAssignmentsModal
          isOpen={isUploadFilesModalOpen}
          module={module}
          assignmentType={AssignmentType.PRACTICAL_ASSIGNMENT}
          onClose={() => setIsUploadFilesModalOpen(false)}
        />
      </>
    ) : (
      // new version of the PA
      <ButtonGhostLink
        className="open-pa-btn"
        to={`/practical-assignment/${module.id}`}
        sx={{ minHeight: "unset", p: 0 }}
      >
        Open
      </ButtonGhostLink>
    );

  switch (module.homework.status) {
    case "pending-evaluation":
      return (
        <Box sx={{ color: "new.primary.green" }}>
          <Text sx={{ display: "inline" }}>Submitted</Text>
          <Text as="span" sx={{ px: "10px" }}>
            |
          </Text>
          {OpenAssignmentsButton}
        </Box>
      );
    case "open":
      return <Box sx={{ color: "new.secondary.grey" }}>{OpenAssignmentsButton}</Box>;
    case "completed":
      return (
        <Box sx={{ color: "new.primary.green" }}>
          <Text sx={{ display: "inline" }}>Graded</Text>
          <Text as="span" sx={{ px: "10px" }}>
            |
          </Text>
          {OpenAssignmentsButton}
        </Box>
      );
    case "started":
      return (
        <Box sx={{ color: "new.secondary.grey" }}>
          <Text sx={{ display: "inline" }}>
            Closes on {dateFormatter.format(module.homework.end)}
          </Text>
          <Text as="span" sx={{ px: "10px" }}>
            |
          </Text>
          {OpenAssignmentsButton}
        </Box>
      );
    case "closed":
      return <Box sx={{ color: "new.secondary.grey" }}>Closed</Box>;
    case "overdue":
      return (
        <Box sx={{ color: "new.failingRed" }}>
          <Text sx={{ display: "inline" }}>Overdue</Text>
          <Text as="span" sx={{ px: "10px" }}>
            |
          </Text>
          {OpenAssignmentsButton}
        </Box>
      );
    case "upcoming":
      return (
        <Box sx={{ color: "new.secondary.grey" }}>
          Opens on {dateFormatter.format(module.homework.start)}
        </Box>
      );
    default:
      return null;
  }
};

const AssessmentStatus: React.FC<{ module: Module }> = ({ module }) => {
  switch (module.assessment.status) {
    case "pending-evaluation":
      return <Box sx={{ color: "new.primary.green" }}>Submitted</Box>;
    case "open":
      return (
        <Box sx={{ color: "new.secondary.grey" }}>
          <OpenAssignmentButton url={module.assessment.url} />
        </Box>
      );
    case "completed":
      return <Box sx={{ color: "new.primary.green" }}>Graded</Box>;
    case "started":
      return (
        <Box sx={{ color: "new.secondary.grey" }}>
          <Text sx={{ display: "inline" }}>
            Closes on {dateFormatter.format(module.assessment.end)}
          </Text>
          <Text as="span" sx={{ px: "10px" }}>
            |
          </Text>
          <OpenAssignmentButton url={module.assessment.url} />
        </Box>
      );
    case "closed":
      return <Box sx={{ color: "new.secondary.grey" }}>Closed</Box>;
    case "locked":
      return <Box sx={{ color: "new.secondary.grey" }}>Locked</Box>;
    case "overdue":
      return (
        <Box sx={{ color: "new.failingRed" }}>
          <Text sx={{ display: "inline" }}>Overdue</Text>
          <Text as="span" sx={{ px: "10px" }}>
            |
          </Text>
          <OpenAssignmentButton url={module.assessment.url} />
        </Box>
      );
    case "upcoming":
      return (
        <Box sx={{ color: "new.secondary.grey" }}>
          Opens on {dateFormatter.format(module.assessment.start)}
        </Box>
      );
    default:
      return null;
  }
};

const CourseEvaluationStatus: React.FC<{ module: Module }> = ({ module }) => {
  switch (module.feedback.status) {
    case "completed":
      return <Box sx={{ color: "new.primary.green" }}>Completed</Box>;
    case "started":
      return (
        <Box sx={{ color: "new.secondary.grey" }}>
          <Text sx={{ display: "inline" }}>In progress</Text>
          <Text as="span" sx={{ px: "10px" }}>
            |
          </Text>
          <OpenAssignmentButton url={module.feedback.url} />
        </Box>
      );
    default:
      return (
        <Box sx={{ color: "new.secondary.grey" }}>
          <OpenAssignmentButton url={module.feedback.url} />
        </Box>
      );
  }
};
