import * as React from "react";
import { Box, Heading, Text, Textarea, Select, Input } from "theme-ui";
import { TextareaInput } from "@sparkademy/app-common/elements/Textarea";

export const pronounOpts = [
  { value: "", label: "Select an option..." },
  { value: "he/him", label: "He/Him" },
  { value: "she/her", label: "She/Her" },
  { value: "they/them", label: "They/Them" },
  { value: "other", label: "Other" },
];

export const roleOpts = [
  { value: "", label: "Select an option..." },
  { value: "channel_engagement", label: "Channel Engagement" },
  { value: "consumer_experience", label: "Consumer Experience" },
  { value: "continuous_improvement", label: "Continuous Improvement" },
  { value: "customer_support", label: "Customer Support/Success" },
  { value: "data_analytics", label: "Data & Analytics" },
  { value: "digital", label: "Digital" },
  { value: "finance", label: "Finance" },
  { value: "hr", label: "HR" },
  { value: "innovation", label: "Innovation" },
  { value: "marketing", label: "Marketing" },
  { value: "operations", label: "Operations" },
  { value: "organizational_development", label: "Organizational Development" },
  { value: "people", label: "People" },
  { value: "procurement", label: "Procurement" },
  { value: "production_manufacturing", label: "Production/Manufacturing" },
  { value: "quality", label: "Quality" },
  { value: "r&d", label: "R&D" },
  { value: "retail", label: "Retail" },
  { value: "sales", label: "Sales" },
  { value: "sustainability", label: "Sustainability" },
  { value: "transformation", label: "Transformation" },
  { value: "strategic_role", label: "Strategic Role" },
  { value: "other", label: "Other" },
];

export const responsibilityOpts = [
  { value: "", label: "Select an option..." },
  { value: "analyst", label: "Analyst" },
  { value: "consultant", label: "Consultant" },
  { value: "director", label: "Director" },
  { value: "executive", label: "Executive" },
  { value: "global", label: "Global" },
  { value: "head_of", label: "Head Of" },
  { value: "lead", label: "Lead" },
  { value: "manager", label: "Manager" },
  { value: "operator", label: "Operator" },
  { value: "project_manager", label: "Project Manager" },
  { value: "scientist", label: "Scientist" },
  { value: "specialist", label: "Specialist" },
  { value: "vp", label: "VP" },
  { value: "other", label: "Other" },
];


export const occupationOpts = [
  { value: "", label: "Select an option..." },
  { value: "accountant", label: "Accountant" },
  { value: "actor/entertainer", label: "Actor/Entertainer" },
  { value: "architect", label: "Architect" },
  { value: "athlete", label: "Athlete" },
  { value: "banker/financial_advisor", label: "Banker/Financial Advisor" },
  { value: "business_owner/entrepreneur", label: "Business Owner/Entrepreneur" },
  { value: "chef", label: "Chef" },
  { value: "civil_servant", label: "Civil Servant" },
  { value: "cleaner", label: "Cleaner" },
  { value: "clerical_worker/receptionist", label: "Clerical Worker/Receptionist" },
  { value: "construction_worker", label: "Construction Worker" },
  { value: "consultant", label: "Consultant" },
  { value: "designer", label: "Designer (Other than Graphic)" },
  { value: "doctor", label: "Doctor" },
  { value: "driver", label: "Driver" },
  { value: "editor/writer", label: "Editor/Writer" },
  { value: "skilled_tradesperson", label: "Electrician, Plumber, Carpenter" },
  { value: "engineer", label: "Engineer" },
  { value: "farmer/agricultural_worker", label: "Farmer/Agricultural Worker" },
  { value: "firefighter", label: "Firefighter" },
  { value: "freelancer", label: "Freelancer" },
  { value: "graphic_designer", label: "Graphic Designer" },
  { value: "healthcare_professional", label: "Healthcare Professional" },
  { value: "hr_professional", label: "HR Professional" },
  { value: "it_professional", label: "IT Professional" },
  { value: "journalist", label: "Journalist" },
  { value: "lawyer", label: "Lawyer" },
  { value: "lecturer/teacher", label: "Lecturer/Teacher" },
  { value: "manager/administrator", label: "Manager/Administrator" },
  { value: "marketer/public_relations", label: "Marketer/Public Relations" },
  { value: "mechanic", label: "Mechanic" },
  { value: "military_personnel", label: "Military Personnel" },
  { value: "nurse", label: "Nurse" },
  { value: "paramedic", label: "Paramedic" },
  { value: "pharmacist", label: "Pharmacist" },
  { value: "police_officer", label: "Police Officer" },
  { value: "programmer", label: "Programmer/Software Developer" },
  { value: "professor/researcher", label: "Professor/Researcher" },
  { value: "real_estate_agent", label: "Real Estate Agent" },
  { value: "retail_worker", label: "Retail Worker" },
  { value: "sales_representative", label: "Sales Representative" },
  { value: "scientist", label: "Scientist" },
  { value: "secretary", label: "Secretary" },
  { value: "social_worker", label: "Social Worker" },
  { value: "student", label: "Student" },
  { value: "technician", label: "Technician" },
  { value: "translator/interpreter", label: "Translator/Interpreter" },
  { value: "unemployed", label: "Unemployed" },
  { value: "waiter/waitress", label: "Waiter/Waitress" },
  { value: "other", label: "Other" },
];

export const countryOpts = [
  { value: "", label: "Select an option..." },
  { value: "algeria", label: "Algeria" },
  { value: "argentina", label: "Argentina" },
  { value: "australia", label: "Australia" },
  { value: "austria", label: "Austria" },
  { value: "belgium", label: "Belgium" },
  { value: "brazil", label: "Brazil" },
  { value: "canada", label: "Canada" },
  { value: "chile", label: "Chile" },
  { value: "china", label: "China" },
  { value: "colombia", label: "Colombia" },
  { value: "czech_republic", label: "Czech Republic" },
  { value: "denmark", label: "Denmark" },
  { value: "egypt", label: "Egypt" },
  { value: "finland", label: "Finland" },
  { value: "france", label: "France" },
  { value: "germany", label: "Germany" },
  { value: "greece", label: "Greece" },
  { value: "hungary", label: "Hungary" },
  { value: "india", label: "India" },
  { value: "indonesia", label: "Indonesia" },
  { value: "iran", label: "Iran" },
  { value: "iraq", label: "Iraq" },
  { value: "ireland", label: "Ireland" },
  { value: "israel", label: "Israel" },
  { value: "italy", label: "Italy" },
  { value: "japan", label: "Japan" },
  { value: "kenya", label: "Kenya" },
  { value: "malaysia", label: "Malaysia" },
  { value: "mexico", label: "Mexico" },
  { value: "morocco", label: "Morocco" },
  { value: "netherlands", label: "Netherlands" },
  { value: "new_zealand", label: "New Zealand" },
  { value: "nigeria", label: "Nigeria" },
  { value: "norway", label: "Norway" },
  { value: "peru", label: "Peru" },
  { value: "philippines", label: "Philippines" },
  { value: "poland", label: "Poland" },
  { value: "portugal", label: "Portugal" },
  { value: "romania", label: "Romania" },
  { value: "russia", label: "Russia" },
  { value: "saudi_arabia", label: "Saudi Arabia" },
  { value: "singapore", label: "Singapore" },
  { value: "south_africa", label: "South Africa" },
  { value: "south_korea", label: "South Korea" },
  { value: "spain", label: "Spain" },
  { value: "sweden", label: "Sweden" },
  { value: "switzerland", label: "Switzerland" },
  { value: "thailand", label: "Thailand" },
  { value: "turkey", label: "Turkey" },
  { value: "uae", label: "United Arab Emirates" },
  { value: "united_kingdom", label: "United Kingdom" },
  { value: "united_states", label: "United States" },
  { value: "vietnam", label: "Vietnam" },
];

export const referralSourceOpts = [
  { value: "", label: "Select an option..." },
  { value: "colleagues", label: "Heard about it from colleagues" },
  { value: "company_community", label: "Through a company internal community" },
  { value: "l&d", label: "Heard about it from Learning and Development program" },
  { value: "manager", label: "My manager recommended it to me" },
  {
    value: "previous_training",
    label: "Through a previous training (e.g., Innovation Masterclass)",
  },
  { value: "all_above", label: "All the options above" },
];

export const motivationOpts = [
  { value: "", label: "Select an option..." },
  { value: "superiors_requirement", label: "Requirement from your superiors" },
  { value: "personal_interest", label: "Personal curiosity and interest about the topic" },
  { value: "future_of_work", label: "Belief these skills are relevant for the future of work" },
  {
    value: "professional_improvement",
    label: "Belief these skills will help perform better professionally",
  },
  { value: "other", label: "Other" },
];

export const FormInputField: React.FC<{
  text: string;
  value: string;
  hasError: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ text, value, hasError, onChange }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    }}
  >
    <Text sx={{ mb: 3, maxWidth: [330, 500] }}>{text}</Text>
    <Input
      value={value}
      onChange={onChange}
      sx={{
        width: [330, 500],
        mb: [6, 8],
        border: "2px solid",
        borderColor: hasError ? "new.failingRed" : "auto",
        fontSize: "16px",
        lineHeight: "22px",
        py: "16px",
        px: "16px",
        ":focus-visible": {
          outline: "none",
        },
        ":active,:focus": {
          borderColor: "primary",
        },
      }}
    />
  </Box>
);

export const FormTextField: React.FC<{
  id: string;
  text: string;
  value: string;
  hasError: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ id, text, value, hasError, onChange }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    }}
  >
    <Text sx={{ mb: 3, maxWidth: [330, 500] }}>{text}</Text>
    <TextareaInput
      id={id}
      value={value}
      onChange={onChange}
      sx={{
        borderColor: hasError ? "new.failingRed" : "auto",
        width: [330, 500],
        mb: [6, 8],
        fontSize: "16px",
        lineHeight: "22px",
        py: "16px",
        px: "16px",
        ":active,:focus": {
          borderColor: "primary",
        },
      }}
    />
  </Box>
);

export const FormSelectField: React.FC<{
  id: string;
  text: string;
  selected: string;
  options: { value: string; label: string }[];
  hasError: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  note?: string;
}> = ({ id, text, selected, options, hasError, onChange, note }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      mb: [6, 8],
    }}
  >
    <Text sx={{ mb: 2, maxWidth: [330, 500] }}>{text}</Text>
    {note && <Text sx={{ fontSize: "0.8em", mb: 3, maxWidth: [330, 500] }}>{note}</Text>}
    <Select
      id={id}
      sx={{
        border: "2px solid",
        borderColor: hasError ? "new.failingRed" : "auto",
        py: "16px",
        px: "16px",
        outline: "none",
        width: [330, 500],
        ":active,:focus": {
          borderColor: "primary",
        },
      }}
      onChange={onChange}
      value={selected}
    >
      {options.map((option, idx) => (
        <option key={idx} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  </Box>
);

export const validateFields = (
  form: { [attr: string]: any },
  attributeToLabelMap: { [attr: string]: string }
) => {
  const errors: { [field: string]: string } = {};
  Object.entries(form).forEach(([field, value]) => {
    if (!value) {
      errors[field] = `${attributeToLabelMap[field]} is required`;
    }
  });
  return errors;
};
