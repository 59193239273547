import React from "react";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import ky from "ky";
import { useAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { getEnrolledModules, getStatus, updateUserAttributes } from "../services/http-api-service";
import { dateStateAtom } from "../stores/current-date";
import { isSyncingAtom, levelsAtom, modulesAtom } from "@sparkademy/app-common/stores/user";
import { useUserData } from "../hooks/useUserData";
import { useInterval } from "../hooks/useInterval";
import { usePageVisibility } from "../hooks/usePageVisibility";
import { Logger } from "@sparkademy/app-common/services/logger-service";

const checkAppVersion = async () => {
  try {
    const rawVersion = await ky.get("/version.txt", { timeout: 5000 });
    const version = await rawVersion.text();
    // check whether version matches the pattern x.x.x
    const isVersionValid = /\d+\.\d+\.\d+/.test(version);

    if (!window.CURRENT_VERSION) {
      window.CURRENT_VERSION = version;
      if (process.env.NODE_ENV === "production") {
        Sentry.init({
          dsn: "https://23c66018082148a58793f56ab37f4d38@o380754.ingest.sentry.io/5252559",
          ignoreErrors: [
            "Non-Error exception captured",
            "Non-Error promise rejection captured",
            "TimeoutError",
            "AbortError",
            "NetworkError",
          ],
          release: version,
        });
      }
    } else if (isVersionValid && window.CURRENT_VERSION !== version) {
      window.location.reload();
    }
  } catch (err) {
    Logger.error(err);
  }
};

export const UserDataSynchronizer: React.FC = () => {
  const { currentUser, loaded, logout } = useSessionContext();
  const setLevels = useUpdateAtom(levelsAtom);
  const setModules = useUpdateAtom(modulesAtom);
  const setIsSyncing = useUpdateAtom(isSyncingAtom);
  const [date] = useAtom(dateStateAtom);
  const { isSyncing, sync } = useUserData();
  const history = useHistory();

  React.useEffect(() => {
    if (currentUser) {
      setIsSyncing(true);
      getEnrolledModules(currentUser, date)
        .then(setModules)
        .finally(() => setIsSyncing(false));
    }
  }, [currentUser, setModules, date]);

  React.useEffect(() => {
    async function initialLoad() {
      checkAppVersion();

      if (loaded && currentUser) {
        const newStatus = await getStatus(currentUser);
        if (newStatus) {
          setLevels(newStatus.levels);

          if (
            newStatus.currentCohortId !== "" &&
            newStatus.currentCohortId !== currentUser.data.cohort_id &&
            !["unit_test", "unit_a_test"].includes(currentUser.data.cohort_id)
          ) {
            logout();
            history.push("/error?reason=cohort-changed");
          }
        }
      }
    }
    initialLoad();
  }, [loaded, currentUser, date, logout, setLevels, history]);

  usePageVisibility(isVisible => {
    if (!isVisible) {
      return;
    }
    if (!currentUser) {
      return;
    }

    if (isSyncing) {
      return;
    }

    sync(currentUser);
  });

  const delay = 1000 * 60 * 5; // every 5 minutes;
  useInterval(() => {
    const currentTimezoneOffset = new Date().getTimezoneOffset();

    if (currentUser && loaded) {
      sync(currentUser);

      if (currentUser.timeZoneOffset !== currentTimezoneOffset) {
        updateUserAttributes(
          {
            timeZoneOffset: new Date().getTimezoneOffset(),
            timeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          currentUser
        );
      }
    }
  }, delay);

  useInterval(() => {
    checkAppVersion();
  }, delay);

  return null;
};
