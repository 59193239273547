import { Box, Heading } from "theme-ui";

import React from "react";
import { Layout, LayoutHeader } from "../components/Layout";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { Redirect } from "react-router-dom";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationPersonSad } from "@sparkademy/app-common/materials/illustrations/person-sad.svg";
import { ReactComponent as IllustrationPerson } from "@sparkademy/app-common/materials/illustrations/person.svg";
import { ReactComponent as LogoHat } from "@sparkademy/app-common/materials/logos/logo-hat.svg";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { uiCopy } from "../data/ui-copy";
import { addUserExtraInfo, signup } from "../services/http-api-service";
import isEmail from "@sparkademy/app-common/utils/isEmail";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { PrivacyTermsCheckbox } from "../components/PrivacyTermsCheckbox";
import {
  countryOpts,
  FormInputField,
  FormSelectField,
  pronounOpts,
  occupationOpts,
  validateFields,
} from "@sparkademy/app-common/components/FormFields";
import { UserExtraInfo } from "@sparkademy/app-common/models/user";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";

const referralSourceOpts = [
  { value: "", label: "Select an option..." },
  { value: "search_engines", label: "Search Engines (e.g., Google, Bing, Yahoo)" },
  { value: "social_media", label: "Social Media (e.g., Facebook, Twitter, LinkedIn)" },
  { value: "online_ad", label: "Online Advertising (e.g., Google Ads, LinkedIn Ads)" },
  { value: "online_events", label: "Online Events and Webinars" },
  { value: "word_of_mouth", label: "Word of Mouth" },
];
enum STATES {
  IDLE = "IDLE",

  FORM_LOADING = "FORM_LOADING",
  FORM_ERROR = "FORM_ERROR",
  FORM_SUCCESS = "FORM_SUCCESS",
}

type FormState = {
  firstName: string;
  lastName: string;
  email: string;
  errors: { [field: string]: string };
} & Pick<UserExtraInfo, "pronoun" | "occupation" | "country" | "referralSource">;

export const Signup: React.FC = () => {
  const { currentUser } = useSessionContext();
  const [state, setState] = React.useState<STATES>(STATES.IDLE);

  // eslint-disable-next-line
  const [error, setError] = React.useState<string>("");
  const [termsConsentGiven, setTermsConsentGiven] = React.useState<boolean>(false);

  const [values, setValues] = React.useState<FormState>({
    firstName: "",
    lastName: "",
    email: "",
    pronoun: "",
    occupation: "",
    country: "",
    referralSource: "",
    errors: {},
  });

  // get course run id from query string by using useQuery hook
  const query = useQuery();
  const courseRunId = query.get("crid");

  const onChangeHandler = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
    setValues({
      ...values,
      [field]: event.currentTarget.value,
      errors: { ...values.errors, [field]: "" },
    });

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (state === STATES.FORM_LOADING) {
      return;
    }

    const attributeToLabelMap = {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      pronoun: "Pronoun",
      occupation: "Occupation",
      country: "Country",
      referralSource: "Referral Source",
    };

    const errors: { [field: string]: string } = validateFields(values, attributeToLabelMap);
    if (!errors["email"] && !isEmail(values.email)) {
      errors["email"] = "Email should be valid";
    }

    if (Object.keys(errors).length) {
      setValues(prev => ({
        ...prev,
        errors,
      }));
      return;
    }

    setState(STATES.FORM_LOADING);

    try {
      const res = await signup(values.firstName, values.lastName, values.email, courseRunId);

      if (res.error) {
        setError(res.error);
        setState(STATES.FORM_ERROR);
        return;
      }

      if (res.id) {
        await addUserExtraInfo(res.id, {
          pronoun: values.pronoun,
          occupation: values.occupation,
          country: values.country,
          referralSource: values.referralSource,
        });

        setState(STATES.FORM_SUCCESS);
      }
    } catch (ex) {
      setError(ex.message);
      setState(STATES.FORM_ERROR);
    }
  }

  if (currentUser) {
    return <Redirect to={"/"} />;
  }

  if (state === STATES.FORM_LOADING) {
    return (
      <Layout sx={{ bg: "new.primary.white" }}>
        <Loader />
      </Layout>
    );
  }

  const errors = Object.values(values.errors).filter(e => !!e);

  return (
    <LayoutHeader sx={{ bg: "new.primary.white" }}>
      <Container
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: [330, 500],
          pt: 13,
        }}
      >
        <Box>
          {state === STATES.IDLE && (
            <Container
              sx={{
                textAlign: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: 70,
                  svg: {
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <LogoHat />
              </Box>
              <Heading
                as="h1"
                sx={{
                  fontSize: [2, 3],
                  fontWeight: "bold",
                  textAlign: "center",
                  ml: "10px",
                }}
              >
                Create your account
              </Heading>
            </Container>
          )}

          {state === STATES.FORM_ERROR && (
            <Container
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: 170,
                  height: 170,
                  svg: {
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <IllustrationPersonSad />
              </Box>
              <Heading
                as="h1"
                sx={{
                  fontSize: 3,
                  fontWeight: "bold",
                  textAlign: "center",
                  mb: 3,
                }}
              >
                {error.match(/user already exists/i)
                  ? "Account already exists"
                  : uiCopy.LOGIN_ERROR_TITLE}
              </Heading>
              <Narrative
                sx={{
                  mt: 4,
                  a: {
                    color: "brand.blue",
                  },
                }}
              >
                {error.match(/user already exists/i) ? (
                  <>
                    <p>
                      The provided email already have an account associated with it. If you have
                      been here before you can <a href="/login">login</a> instead.
                    </p>
                  </>
                ) : (
                  uiCopy.SIGNUP_ERROR_LEAD()
                )}
              </Narrative>
            </Container>
          )}

          {state === STATES.FORM_SUCCESS ? (
            <Container
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: 170,
                  height: 170,
                  svg: {
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <IllustrationPerson />
              </Box>
              <Heading
                as="h1"
                sx={{
                  fontSize: 3,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {uiCopy.LOGIN_SUBMITTED_TITLE}
              </Heading>
              <Narrative
                sx={{
                  mt: 4,
                }}
              >
                {uiCopy.LOGIN_SUBMITTED_LEAD()}
              </Narrative>
              <Box
                sx={{
                  mt: 6,
                  p: { m: 0, height: "auto" },
                }}
              ></Box>
            </Container>
          ) : null}
        </Box>

        {(state === STATES.IDLE || state === STATES.FORM_ERROR) && (
          <Container
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <form onSubmit={onSubmit} noValidate>
              <Box
                sx={{
                  mt: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <FormInputField
                  text="First Name"
                  value={values.firstName}
                  onChange={onChangeHandler("firstName")}
                  hasError={!!values.errors["firstName"]}
                />

                <FormInputField
                  text="Last Name"
                  value={values.lastName}
                  onChange={onChangeHandler("lastName")}
                  hasError={!!values.errors["lastName"]}
                />

                <FormInputField
                  text="Email"
                  value={values.email}
                  onChange={onChangeHandler("email")}
                  hasError={!!values.errors["email"]}
                />

                <FormSelectField
                  id="pronoun"
                  text="Preferred Pronoun"
                  options={pronounOpts}
                  selected={values.pronoun}
                  hasError={!!values.errors["pronoun"]}
                  onChange={onChangeHandler("pronoun")}
                />

                <FormSelectField
                  id="occupation"
                  text="Occupation"
                  options={occupationOpts}
                  selected={values.occupation || ""}
                  hasError={!!values.errors["occupation"]}
                  onChange={onChangeHandler("occupation")}
                />

                <FormSelectField
                  id="country"
                  text="Country"
                  options={countryOpts}
                  selected={values.country}
                  hasError={!!values.errors["country"]}
                  onChange={onChangeHandler("country")}
                />

                <FormSelectField
                  id="referralSource"
                  text="How did you hear about us?"
                  options={referralSourceOpts}
                  selected={values.referralSource}
                  hasError={!!values.errors["referralSource"]}
                  onChange={onChangeHandler("referralSource")}
                  note="(If more than one apply, please select the most relevant one for you)"
                />
              </Box>

              {!!errors.length && (
                <Box sx={{ color: "new.failingRed", textAlign: "left" }}>
                  <ul style={{ margin: 0 }}>
                    {errors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </Box>
              )}

              <Box sx={{ display: "flex", alignItems: "center", mt: 6 }}>
                <PrivacyTermsCheckbox
                  consentGiven={termsConsentGiven}
                  setConsentGiven={setTermsConsentGiven}
                />
              </Box>

              <Button type="submit" sx={{ mb: 10 }} disabled={!termsConsentGiven}>
                Submit
              </Button>
            </form>
          </Container>
        )}
      </Container>
    </LayoutHeader>
  );
};
